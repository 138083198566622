/*==========================
* Responsive Design
===========================*/

$tv: #{"only screen and (min-width: 3000px)"};
$desktop: #{"only screen and (min-width: 1321px)"};
$tablet: #{"only screen and (min-width: 890px) and (max-width: 1320px)"};
$tablet-up: #{"only screen and (min-width: 890px) and (max-width: 1060px)"};
$phablet: #{"only screen and (min-width: 600px ) and (max-width: 890px)"};
$mobile: #{"only screen and (max-width: 600px)"};
$tiny: #{"only screen and (max-width: 370px)"};
$super-tiny: #{ "only screen and (max-height: 300px)"};
$all-tablet: #{"only screen and (min-width: 640px) and (max-width: 1320px) and (max-height: 1799px)"};
$all-mobile: #{"only screen and (max-width: 640px)"};
$kiosk: #{"only screen and (min-height: 1800px)"};
$not-kiosk: #{"only screen and (max-height: 1800px)"};

/*===================
* Global Dimensions
====================*/

$header-height: 64px;
$wizard-header-height: 100px;
$footer-height: 64px;
$overviews-mobile-height: 300px;
$width-sidebar: 260px;
$margin-sidebar-mobile: 42px;
$margin-sidebar: 30px;
$margin-sidebar-right: 14px;
$sidebar-padding: 12px 3px 12px 18px;
$listitem-gap-margin: 26px;
$margin-widgetbar: 40px;
$round-radius: 5px;

/*===================
* Menus
====================*/

$menu-caret-width: 30px;
$menu-head-height: 34px;
$menu-item-height: 60px;

/*===================
* Colors
====================*/
$error-color: #F1416C;
$red: #F1416C;
$red-hover: #D9214E;
$green: #4AB58E;
$green-hover: #2BA579;
$blue: #2E8BCC;

$bright-background-color: white;
$dark-sidebar-background-color: rgba(0, 0, 0, 0.82);

$text-color: #1A1A1A;
$input-color: #424242;
$dark-grey: #5A5A5A;
$info-color: #767676;
$light-medium-grey: #989696;
$disabled-color: #B1B1B1;
$untouched-grey: #C9C9C7;
$divider-grey: #DADADA;

$cod-grey: #181818;
$charade-grey: #272b36;
$min-shaft-grey: #333;
$tundora-grey: #444;
$emperor-grey: #555;
$boulder-grey: #777;
$grey: #888;
$silver: #bdbdbd;
$alto-grey: #d2d2d2;
$mercury-grey: #e5e5e5;
$concrete-grey: #f2f2f2;
$alabaster-grey: #f9f9f9;
$confirmed-color: #366CF9;
$pre-checked-in-color: #8950FC;

.button-radius {
  border-radius: 2px;
}

@import './extensions';
@import './fonts';
