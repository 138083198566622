$kiosk-footer-mobile-height: 80px;

app-check-in, app-check-out {
  .container {
    min-height: 100vh;
  }

  padding: 0 !important;
}

#container {
  &.overlay {
    form, #steps-container, .page-info, app-pms-confirm .content {
      display: none;
    }

    .grid-container {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    app-check-in > app-loader, app-check-out > app-loader {
      display: none;
    }
  }

  app-country-box-group > .boxes-container {
    @media #{"only screen and (min-width: 990px) and (max-width: 1800px)"} {
      grid-template-columns: repeat(3, 1fr) !important;
    }
  }

  &.wizard {
    .grid-container {
      padding: 30px 43px 130px 43px;
      @media #{$all-tablet} {
        padding: 40px 50px 170px 50px;
      }
      @media #{$desktop}, #{$kiosk} {
        padding: 60px 321px 178px 321px;
      }
      @media #{$kiosk} {
        padding: 60px 100px 178px 100px;
      }
    }

    .component-overlay {
      max-height: calc(100vh - #{$header-height} - 140px);

      @media #{$all-tablet}, #{$kiosk} {
        width: 70vw
      }

      @media #{$desktop} {
        width: 50vw
      }
    }

    &.frame {
      .iframe_container {
        height: calc(100vh - 180px) !important;
      }
    }

    footer {
      left: 0 !important;
      bottom: 0 !important;
      width: 100vw !important;
      padding: 20px 0 !important;
    }

    app-country-box-group > .boxes-container {
      @media #{"only screen and (min-width: 1320px) and (max-width: 1700px)"} {
        grid-template-columns: repeat(3, 1fr) !important;
      }
    }
  }

  &:not(.wizard) {
    .grid-container {
      padding: 25px 43px 58px 43px;
      @media #{$tablet} {
        padding: 0 0 58px 0;
      }
      @media #{$mobile} {
        padding: 25px 0;
      }
      @media #{$tiny} {
        padding: 25px 5px;
      }
    }

    .component-overlay {
      max-height: calc(100vh - #{$header-height} - #{$kiosk-footer-mobile-height});

      @media #{$all-tablet}, #{$desktop}, #{$kiosk}  {
        max-height: calc(100vh - #{$header-height} - 140px);
      }
    }

    app-country-box-group > .boxes-container {
      @media #{"only screen and (min-width: 990px) and (max-width: 1800px)"} {
        grid-template-columns: repeat(3, 1fr) !important;
      }
    }

    .background {
      width: calc(100vw + $width-sidebar) !important;

      @media #{$mobile}, #{$phablet}, #{$desktop} {
        width: calc(100vw) !important;
      }
    }

    .loader-container {
      height: auto;
      width: 100%;
      position: relative;
      padding: 0 0 43px 0;
      background: none;
    }

    app-pms-ci-general, app-pms-co-general {
      .modal-box {
        @media #{$mobile} {
          transform: translate(-50%, 50%) !important;
        }
      }
    }
  }

  app-conflict {
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 5;
  }

  app-pms-cico-overlay {
    #logo {
      text-align: center;
    }

    .close-icon {
      top: 100px;
    }
  }

  &.cico {
    &.frame {
      .iframe_container {
        height: calc(100vh - 100px) !important;
      }

      app-steps-indicator, .page-info {
        display: none;
      }

      @media #{$mobile} {
        .iframe_container {
          height: calc(100vh - $header-height) !important;
        }

        .grid-container {
          padding: 0;
        }
      }
    }

    section#content:not(.is-safari) {
      @media #{$mobile} {
        min-height: calc(100vh - #{$header-height} - #{$kiosk-footer-mobile-height});
        padding: $header-height 20px $kiosk-footer-mobile-height 20px;
      }

      @media #{$all-tablet} {
        min-height: calc(100vh - #{$kiosk-footer-mobile-height});
      }
    }

    footer {
      @extend #container;
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100vw;
      text-align: center;
      background-color: white;
      padding: 20px 0;
      box-sizing: border-box;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
      z-index: 2;

      @media #{$tablet} {
        left: $width-sidebar;
        width: calc(100vw - $width-sidebar);
        height: auto;
      }

      @media #{$desktop} {
        left: $width-sidebar;
        right: $width-sidebar;
        width: calc(100vw - 2 *($width-sidebar));
        height: auto;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 13.5px;
        border: 1px solid transparent;
        border-radius: 2px;
        width: 274px;
        height: 40px;
        margin: 0 7px;
        font-size: 13px;

        @media #{$all-tablet} {
          width: 620px;
          height: 50px;
          margin: 0 15px;
          font-size: 16px;
        }

        @media #{$desktop} {
          margin: 0 20px;
          width: 303px;
          height: 60px;
          font-size: 20px;
        }

        @media #{$kiosk} {
          margin: 0 20px;
          width: 620px;
          height: 60px;
          font-size: 22px;
        }

        &.small-width {
          &.btn-outline {
            width: 120px;
          }

          &.btn-filled {
            width: 140px;
          }

          &.btn-outline, &.btn-filled {
            @media #{$all-tablet} {
              width: 295px;
            }
            @media #{$desktop}, #{$kiosk} {
              width: 274px;
            }
          }
        }

        &:hover {
          cursor: pointer;
        }

        &.btn-outline {
          background-color: transparent;
        }

        &:disabled {
          color: white;
          border-color: $disabled-color;
          background-color: $disabled-color;
          cursor: initial;
        }
      }
    }

    app-form-legal {
      display: block;

      a {
        text-decoration: underline !important;
      }
    }

    #activate {
      @media #{$mobile} {
        display: none;
      }
    }
  }
}

body.no-sidebar {
  #container.cico {
    footer {
      @media #{$desktop} {
        right: 0;
        width: calc(100vw - $width-sidebar);
      }
    }
  }

  .generic-overlay, .component-overlay {
    &.with_back {
      @media #{$desktop} {
        left: calc(50% + ($width-sidebar / 2));
      }
    }
  }
}

#alert-box {
  @include box-sizing;
  width: 100%;
  text-align: center;
  color: $charade-grey;
  padding: 32px 24px;
  margin-bottom: 40px;
  border-radius: 2px;

  @media #{$mobile} {
    margin: 20px 0 0 0;
    padding: 24px 16px;
  }

  &.info {
    background: #ecf5ff;
  }

  &.success {
    background: #e3f1df;
  }

  &.notice {
    background: #fff4ec;
  }

  &.error {
    background: #fbeae5;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 4px;
  }

  p {
    margin: 0 !important;
    font-size: inherit;
  }
}

/*==================
* Modal-box
===================*/
.modal-box {
  max-width: 620px;
  border-radius: 4px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: white;
  padding: 40px;
  text-align: center;
  overflow-y: auto;
  max-height: 74vh;
  margin: 50px auto 7px auto;
  @include box-sizing;
  @extend .scrollbar;

  @media #{$mobile} {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 24px;
    min-width: 90vw;
  }

  @media #{$desktop}, #{$kiosk} {
    margin-top: 40px;
    max-height: 85vh;
  }

  > i {
    font-size: 65px;
    margin-bottom: 16px;
  }

  h1, h1 a {
    font-size: 22px !important;
    font-weight: bold !important;
    @media #{$mobile} {
      font-size: 20px !important;
    }
  }

  h2 {
    font-size: 20px;
    @media #{$mobile} {
      font-size: 18px;
    }
  }

  .desc {
    em {
      font-size: 18px;
      line-height: 1.33;
      color: $charade-grey;
      text-align: justify !important;
      margin: 0 20px !important;
      @media #{$mobile} {
        margin: 0 !important;
      }

      & + em {
        padding-top: 8px;
      }
    }
  }
}

.page-info {
  @extend .animation-in;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  margin-bottom: 15px;
  background-color: white;

  @media #{$all-tablet}, #{$desktop}, #{$kiosk} {
    padding: 24px;
  }
}

app-pms-cico-overlay {
  button.close {
    margin-top: 0;
    margin-bottom: 16px;
  }
}

.input-container.disabled .info-wrapper {
  display: none !important;
}

.camera-button {
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: max-content;
  height: 42px;
  padding: 0 20px;
  border: none;
  font-size: 13px;
  font-weight: 500;

  @media #{$all-tablet} {
    margin-top: 6px;
    height: 52px;
    column-gap: 15px;
    font-size: 16px;
  }

  @media #{$desktop}, #{$kiosk} {
    height: 62px;
    column-gap: 15px;
    font-size: 18px;
  }

  &:disabled {
    background-color: $disabled-color;
    color: white;
    cursor: default;

    i {
      color: white;
    }
  }

  i {
    font-size: 18px;

    @media #{$all-tablet} {
      font-size: 20px;
    }

    @media #{$desktop}, #{$kiosk} {
      font-size: 22px;
    }
  }
}

app-success-screen {
  .text a {
    text-decoration: underline !important;
    font-weight: 700 !important;
  }
}

.toast-top-center {
  @media #{$kiosk} {
    top: 80px;
    font-size: 18px;
  }
}
