.toggleSlider {
  height: 0 !important;
  width: 0 !important;
  visibility: hidden;
  &:checked + .toggler:after {
    left: calc(100% - 3px);
    transform: translateX(-100%);
  }
  &:not(:checked) + .toggler .on {
    display: none;
  }
  &:checked + .toggler {
    .on {
      display: block;
    }
    .off {
      display: none;
    }
  }
  &:disabled + .toggler {
    background: $mercury-grey;
  }
}

.toggler {
  cursor: pointer;
  width: 60px;
  height: 30px;
  background: $alto-grey;
  display: block;
  border-radius: 40px;
  position: relative;
  margin-bottom: 15px;
  float: right;
  &.left{
    float: left;
  }
  &.opener {
    margin-bottom: 35px;
  }

  .description{
    margin: 0 0 0 73px;
    display: inline-block;
    width: calc(100vw - 103px);
    max-width: 727px;
  }

  .on, .off
  {
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    font-size: 14px;
    font-weight: bold;
  }

  .on {
    left: 30%
  }

  .off {
    color: #555;
    right: 10%
  }

  + small {
    position: absolute;
    margin: -14px 0 0 73px;
  }

  &:active:after {
    width: 30px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 26px;
    height: 26px;
    background: white;
    border-radius: 30px;
    transition: 0.3s;
  }
}
