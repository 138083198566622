@import '~ngx-toastr/toastr.css';

.overlay-container {
  &.curtain {
    z-index: 99999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.14);
  }
}

.toast-container {
  .ngx-toastr {
    width: auto !important;
    min-width: 300px;
    max-width: 500px;
    top: 16px;
    @media #{$mobile} {
      top: $header-height;
    }
  }
  .toast-error {
    color: $charade-grey !important;
    background-color: #fbeae5;
  }
  .toast-success {
    color: $charade-grey !important;
    background-color: #e3f1df;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(33,43,53)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E") !important;
  }

  .toastr-button {
    display: table;
    margin-top: 6px;
  }
}
