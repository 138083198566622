@mixin fade_in($speed:0.2s) {
  -webkit-animation: fadeIn $speed linear;
  -moz-animation: fadeIn $speed linear;
  -ms-animation: fadeIn $speed linear;
  -o-animation: fadeIn $speed linear;
  animation: fadeIn $speed linear;
}

@keyframes fadeIn {
  from {
    transform: scale(.8);
    -o-transform: scale(.8);
    -ms-transform: scale(.8);
    -moz-transform: scale(.8);
    -webkit-transform: scale(.8);
    transition-property: all;
  }
  to {
    transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 1;
  }
}

@mixin animation($value) {
  -webkit-animation: unquote($value);
  -moz-animation: unquote($value);
  -o-animation: unquote($value);
  animation: unquote($value);
}

@mixin animation-property($property, $value) {
  -webkit-animation-#{$property}: unquote($value);
  -moz-animation-#{$property}: unquote($value);
  -o-animation-#{$property}: unquote($value);
  animation-#{$property}: unquote($value);
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-o-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@include keyframes(swipe_left) {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(3deg);
  }
  50% {
    transform:  rotate(-15deg) ;
  }
  100% {
    transform:  rotate(0deg) ;
  }
}

@include keyframes(swipe_right) {
  0% {
    transform:  rotate(0deg) ;
  }
  30% {
    transform:  rotate(-3deg) ;
  }
  50% {
    transform:  rotate(15deg) ;
  }
  100% {
    transform:  rotate(0deg) ;
  }
}
